import { useRouteStore } from '~/store/routesStore'
import { DEFAULT_DIAMOND_TYPE, DEFAULT_STONE_TYPE } from '~/constants/shop'
import { SHOP_ROUTES_NAMES } from '~/constants'
import { getGemShapes } from '~/utils/shop/gems.js'

// Type definitions
type RouteMeta = {
  path: string
  aspParams: [string, string][]
}

const parseQuery = (queryString: string): [string, string][] => {
  const normalizedQuery = queryString
    .replace(/CatId/g, 'category')
    .replace(/SubCatId/g, 'subcategory')
    .replace(/Subcategory/g, 'subcategory')
  const searchParams = new URLSearchParams(normalizedQuery)
  return Array.from(searchParams.entries())
}

// Function to build a query string from an array of key-value pairs
const buildQueryString = (entries: [string, string][]): string => {
  const params = new URLSearchParams(entries)
  return params.toString()
}

// Function to find a matching route based on query parameters
const findMatchingRoute = (currentQueryEntries: [string, string][], routes: RouteMeta[]): string | null => {
  const queryObject = Object.fromEntries(currentQueryEntries)

  const matchedRoutes = routes
    .filter((route) => route.aspParams.every(([key, value]) => queryObject[key] === value))
    .sort((a, b) => b.aspParams.length - a.aspParams.length)

  if (matchedRoutes.length) {
    const matchedRoute = matchedRoutes[0]
    const remainingQueryEntries = currentQueryEntries.filter(
      ([key]) => !matchedRoute.aspParams.some(([routeKey]) => routeKey === key),
    )
    const browserQuery = remainingQueryEntries.length > 0 ? '?' + buildQueryString(remainingQueryEntries) : ''
    return matchedRoute.path + browserQuery
  }
}

// Main function to use ASP routes
export function useAspRoutes() {
  const routeStore = storeToRefs(useRouteStore())

  // Get cached routes or filter them if not cached
  const shopRoutes = routeStore.shopRoutes

  // Function to translate Shop ASP route to friendly URL
  const translateShopAspRouteToFriendlyUrl = (aspRoute: string): string => {
    const [path, queryString] = aspRoute.split('?')
    const queryEntries = parseQuery(queryString || '')

    const matchedRoute = findMatchingRoute(queryEntries, shopRoutes.value)
    if (matchedRoute) return matchedRoute

    return aspRoute
  }

  const GEMSTONE_SUBACATEGORY = 48
  const COLORED_GEMSTONE_SUBCATEGORY = 28
  const LAB_GROWTH_SUBCATEGORY = 15
  const NATURAL_SUBCATEGORY = 14

  const subcategoryByDiamondTypeMap = {
    '-4': GEMSTONE_SUBACATEGORY,
    '-3': COLORED_GEMSTONE_SUBCATEGORY,
    '-2': LAB_GROWTH_SUBCATEGORY,
    '-1': NATURAL_SUBCATEGORY,
  }

  // Function to get ASP route by friendly URL
  const getShopAspParamsByFriendlyUrl = (friendlyUrl: string): [string, string][] => {
    const route = shopRoutes.value.find((route) => route.path === friendlyUrl)
    return route ? route.aspParams : []
  }

  // Function to handle Diamond Category specific logic
  const handleDiamondCategory = (_query, browserQuery, isDiamondCategoryItem) => {
    const subcategory = subcategoryByDiamondTypeMap[_query['diamondType']]
    const isGemstoneSubcategory = subcategory === GEMSTONE_SUBACATEGORY

    if (_query['gemshape']) _query['shape'] = _query['gemshape']

    delete _query['gem']
    delete _query['subcategories']

    _query['diamondType'] = _query['diamondType'] || -1
    if (!isDiamondCategoryItem) _query['subcategory'] = subcategory
    _query['stone'] = _query['stoneType'] || DEFAULT_STONE_TYPE
    _query.gemType = _query.stoneType == 2 ? _query.gemType || 366 : null

    if (!isGemstoneSubcategory) {
      browserQuery.set('shape', _query['shape'])
      if (
        (Array.isArray(_query['shapes']) && _query['shapes'].length === 1) ||
        !String(_query['shapes']).includes(',')
      ) {
        browserQuery.set('shape', _query['shapes'])
      } else {
        browserQuery.set('shapes', _query['shapes'])
      }
    }

    // handle diamonds shapes
    if (_query['shapes'] && !isGemstoneSubcategory) {
      if (Array.isArray(_query['shapes']) ? _query['shapes'].length >= 2 : String(_query['shapes']).includes(',')) {
        browserQuery.set('shapes', _query['shapes'])
        delete _query['shape']
        browserQuery.delete('subcategory')
      } else if (Array.isArray(_query['shapes']) && _query['shapes']?.length === 1) {
        _query['shape'] = _query['shapes'][0]
      } else {
        _query['shape'] = _query['shapes']
      }
    } else {
      delete _query['shape']
    }
    // handle gemstone shapes
    if (_query['shapes'] && isGemstoneSubcategory) {
      if (Array.isArray(_query['shapes']) ? _query['shapes'].length >= 2 : String(_query['shapes']).includes(',')) {
        browserQuery.set('gemshapes', _query['shapes'])
      } else {
        browserQuery.set('gemshape', _query['shapes'] || _query['shape'])
      }
    }

    if (!isDiamondCategoryItem) browserQuery.set('item', _query['diamondType'])

    if (_query.stoneType != DEFAULT_STONE_TYPE) {
      browserQuery.set('stone', _query.stoneType)
    }
    if (_query['selectedDiamond']) browserQuery.set('selectedDiamond', _query['selectedDiamond'])

    if (_query['gemType']) _query['gem'] = _query['gemType']
  }

  const handleRingShape = (fromStoneType, toStoneType, shapes) => {
    if (fromStoneType == 2 && toStoneType == 2) {
      // when going from diamond to gemstone
      const gemShape = getGemShapes().find((s) => s.Id == shapes)

      if (gemShape) {
        return gemShape.group.join(',')
      }
    } else if (toStoneType == 2) {
      // handle shapes inside gemstones
      const gemShape = getGemShapes().find((s) => s.group.find((gs) => gs == shapes))

      if (gemShape) {
        return gemShape.group.join(',')
      }
    } else if (fromStoneType == 2) {
      // when going from gemstone to diamond
      const gemShape = getGemShapes().find((s) => s.Id == shapes)

      if (gemShape) {
        return gemShape.group
      }
    }
    return shapes
  }

  // Function to handle Rings Category specific logic
  const handleRingsCategory = (_query, browserQuery) => {
    if (_query['shapes']) {
      if ((Array.isArray(_query['shapes']) && _query['shapes'].length >= 2) || String(_query['shapes']).includes(',')) {
        browserQuery.set('shapes', _query['shapes'])
        delete _query['shape']
        browserQuery.delete('subcategory')
      } else if (Array.isArray(_query['shapes']) && _query['shapes']?.length === 1) {
        _query['shape'] = _query['shapes'][0]
      } else {
        _query['shape'] = _query['shapes']
      }
    } else {
      delete _query['shape']
    }

    if (_query['shapes']) {
      const fromAspParams = Object.fromEntries(getShopAspParamsByFriendlyUrl(window.location.pathname)) // current page aspParams from db
      const fromQuery = new URL(window.location.href).searchParams
      const fromStoneType = fromQuery.get('stone') || fromQuery.get('stoneType') || fromAspParams['stone']
      const toStoneType = _query['stoneType']

      _query['shapes'] = handleRingShape(fromStoneType, toStoneType, _query['shapes'])

      if (_query['shapes'] && typeof _query['shapes'] === 'string' && _query['shapes'].includes(',')) {
        browserQuery.set('shapes', _query['shapes'])
        delete _query['shape']
      } else {
        _query['shape'] = _query['shapes']
        browserQuery.set('shape', _query['shapes'])
      }
    }

    // Handles combined query parameters for stoneType, diamondType, gemType, and fancyColorId
    if (_query['stoneType,diamondType,gemType,fancyColorId']) {
      const values = _query['stoneType,diamondType,gemType,fancyColorId'].split(',')

      if (values[0] && Number(values[0]) != DEFAULT_STONE_TYPE) browserQuery.set('stone', values[0])
      if (!_query['stoneType']) {
        _query['stoneType'] = values[0]
        _query['stone'] = values[0]
      }

      if (values[1] && Number(values[1]) != DEFAULT_DIAMOND_TYPE) browserQuery.set('diamondType', values[1])
      _query['diamondType'] = values[1]

      if (values[2]) browserQuery.set('gem', values[2])
      if (!_query['gemType']) {
        _query['gem'] = values[2]
        _query['gemType'] = values[2]
      }

      if (values[3]) browserQuery.set('fancycolor', values[3])
      _query['fancyColorId'] = values[3]
    }

    if (_query['subcategory']) {
      browserQuery.set('subcategory', _query['subcategory'])
    }

    if (_query['metal']) browserQuery.set('metal', _query['metal'])
  }

  // Main function to translate Shop ASP route by query parameters
  const translateShopAspRouteByQueryNew = (
    to: { query: Record<string, string>; fullPath: string },
    ignoreSubcategory = false,
  ): string => {
    const _query = { ...to.query }
    const isDiamondCategoryItem = _query['step'] === SHOP_ROUTES_NAMES.ITEM_DIAMOND
    const browserQuery = to.query.step ? new URLSearchParams(window.location.search) : new URLSearchParams()
    const isDiamondCategory = _query['category'] == 7

    // console.log('translate start', JSON.parse(JSON.stringify(to.query)))

    if (_query['quickShipping']) browserQuery.set('quickShipping', _query['quickShipping'])

    // Handle subcategories
    if (_query['subcategories']) {
      if (
        (Array.isArray(_query['subcategories']) && _query['subcategories'].length >= 2) ||
        _query['subcategories'].includes(',')
      ) {
        browserQuery.set('subcategories', _query['subcategories'])
        delete _query['subcategory']
      } else if (Array.isArray(_query['subcategories']) && _query['subcategories']?.length === 1) {
        _query['subcategory'] = _query['subcategories'][0]
      } else {
        _query['subcategory'] = _query['subcategories']
      }
    } else {
      delete _query['subcategory']
    }

    if (isDiamondCategory || isDiamondCategoryItem) {
      handleDiamondCategory(_query, browserQuery, isDiamondCategoryItem)
    } else {
      handleRingsCategory(_query, browserQuery)
    }

    if (isDiamondCategoryItem) {
      browserQuery.set('item', _query['item'])
      browserQuery.set('step', _query['step'])
      browserQuery.set('diamondType', _query['diamondType'])
      browserQuery.set('stone', _query['stoneType'])

      // Synchronize query entries
      Object.entries(_query).forEach(([key, value]) => {
        if (browserQuery.get(key) != value) browserQuery.delete(key)
      })
    }

    // Common logic for setting query parameters
    if (_query['subcategory']) browserQuery.set('subcategory', _query['subcategory'])
    if (_query['metal']) browserQuery.set('metal', _query['metal'])
    if (_query['start-diamond']) browserQuery.set('start-diamond', _query['start-diamond'])
    if (_query['diamond']) browserQuery.set('diamond', _query['diamond'])

    // Filter routes with matched query parameters
    const routesWithMatchedQueryParams = shopRoutes.value.filter(
      (route) =>
        route.aspParams!.length &&
        route.aspParams!.every(([key, value]) => {
          switch (key) {
            case 'item':
              return isDiamondCategory || _query[key] == value
            case 'subcategory':
              return ignoreSubcategory || _query[key] == value
            default:
              return _query[key] == value
          }
        }),
    )

    // console.log('translate end', _query)
    // console.log(routesWithMatchedQueryParams)

    if (routesWithMatchedQueryParams.length > 0) {
      // Get the route with the most matched query parameters
      const matchedRoute = routesWithMatchedQueryParams.sort((a, b) => b.aspParams!.length - a.aspParams!.length)[0]

      // Normalize aspParams from the matched route
      matchedRoute.aspParams!.forEach(([key, value]) => {
        if (isDiamondCategoryItem) {
          if (value == browserQuery.get(key)) {
            browserQuery.delete(key)
          }
        } else if (value && browserQuery.has(key)) {
          browserQuery.delete(key)
        }
      })

      // Remove empty query parameters
      Array.from(browserQuery.entries()).forEach(([key, value]) => {
        if (!value || value === 'undefined') {
          browserQuery.delete(key)
        }
      })

      return `${matchedRoute.path}${browserQuery.toString() ? '?' + browserQuery.toString() : ''}`
    }

    // console.log('to (translateShopAspRouteByQueryNew)', to)
    // console.log('No matching route found for', to.fullPath)
    return to.fullPath || to.path
  }

  return {
    getShopAspParamsByFriendlyUrl,
    translateShopAspRouteToFriendlyUrl,
    translateShopAspRouteByQueryNew,
  }
}
